<!--
 * @Description: 预约专家团队咨询
 * @Author: LXG
 * @Date: 2021-08-20
 * @Editors: LXG
 * @LastEditTime: 2021-08-20
-->
<template>
    <div class="expert-advisory">
        <p class="text">使用手机钉钉扫描群二维码入群，进行税费优惠政策远程协助预约。</p>
        <img
            class="qrcode"
            src="~@/assets/images/expert_advisory.jpg"
            alt=""
            width="300px"
        />
    </div>
</template>

<script>
export default {
    name: 'ExpertAdvisory',
    components: {},
    filters: {},
    props: {},
    data() {
        return {

        };
    },
    computed: {},
    methods: {},
    watch: {},
    created() { },
    mounted() { },
}
</script>

<style lang="scss" scoped>
.expert-advisory {
    font-size: 1rem;
    text-align: center;
}
</style>